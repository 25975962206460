(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('./components/filters.js');
require('./components/navigation.js');

var Form = require('./components/form');
$('.form').each(function () {
    new Form($(this));
});

$('.form-ajax').each(function () {

    console.log('form-ajax');
    new Form($(this), true);
});

var subnav = {

    init: function init() {
        $('.subnav .level-1').hover(function () {

            if ($(this).find('a').find('img').length > 0) {
                subnav.hide(1);
                subnav.show($(this), 1);
            }
        });

        $('#mobile-menu li.level-1 img').click(function () {

            var parent = $(this).parent();
            if (parent.hasClass('active')) {
                subnav.hide(1);
            } else {
                subnav.hide(1);
                subnav.show($(this).parent(), 1);
            }
        });

        $('#mobile-menu li.level-0 img').click(function () {
            var parent = $(this).parent();
            if (parent.hasClass('active')) {
                subnav.hide(0);
            } else {
                subnav.hide(0);
                subnav.show($(this).parent(), 0);
            }
        });
    },
    show: function show(element, level) {

        element.addClass("active");
        element.nextUntil(".level-" + level, '.level-' + (level + 1)).addClass("visible");
    },
    hide: function hide(level) {
        $(".level-" + level).removeClass("active");

        for (var i = 2; i != level; i--) {
            $(".level-" + i).removeClass("visible");
        }
    }

};
subnav.init();

var $search = {

    init: function init() {
        $("a.search").click(function () {
            if ($("li.search").width() > 100) {
                $("li.search").animate({ width: '0px' }, 200);
            } else {
                $("li.search").animate({ width: '200px' }, 200);
            }
        });
        $('#search').on("keyup", function () {

            if ($(this).val().length > 2) {
                $.post('/zoeken', {
                    key: $(this).val(),
                    _token: $('meta[name=_token]').attr('content')
                }, function (data, status) {
                    if (status == "success") {
                        $("#search-results ul").replaceWith(data);

                        $("#search-results").slideDown();
                    } else {
                        console.log("iets ging niet goed");
                    }
                });
            } else {
                $("#search-results").slideUp();
            }
        });
    }
};
$search.init();

$("label.checkbox input").click(function () {

    if ($(this).parent().hasClass("active")) {

        $(this).parent().removeClass("active");
    } else {

        $(this).parent().addClass("active");
    }
});

$("select").each(function () {

    $(this).width($(this).width() + 20);
});

var Header = require('./components/headers');
new Header($('header'));

},{"./components/filters.js":2,"./components/form":3,"./components/headers":4,"./components/navigation.js":5}],2:[function(require,module,exports){
'use strict';

$.fn.isOnScreen = function (tolerence) {

    tolerence = tolerence || null;

    var win = $(window);
    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return !(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom + tolerence < bounds.top || viewport.top > bounds.bottom);
};

var $filters = {
    timeout: null,
    el: function el() {
        return $('#filters');
    },
    init: function init() {

        var el = $filters.el();
        if (el.length <= 0) {
            return false;
        }

        $filters.datepickers.init();
        $filters.radius();

        $filters.listeners(el);

        $filters.loader.init();
    },

    listeners: function listeners(el) {
        el.on('change', 'input ,select', $filters.request);
        el.find('.clearAll').click($filters.clearAll);

        $('#order').on('change', $filters.changeOrder);
    },

    changeOrder: function changeOrder() {
        var val = this.value;

        $.post('/order', { 'order': val }).success(function (html) {

            $('.overview').html($(html).find(".overview").html());
            $filters.loader.reInit();
        }).error(function () {});
    },

    radius: function radius() {

        var slider = document.getElementById('radius');
        var id = $(slider).attr('id');
        var start = 50;

        $(slider).after('<input type="hidden" value="' + start + '" name="' + id + '" />');
        noUiSlider.create(slider, {
            start: start,
            step: 5,
            connect: "lower",
            range: {
                'min': 5,
                'max': 250
            },
            format: wNumb({
                decimals: 0
            })
        });

        slider.noUiSlider.on('update', function (values, handle) {

            $(".radius_value").html(values[0] + ' km');
            $('input[name="' + id + '"]').val(values[0]);
        });
        slider.noUiSlider.on('change', $filters.request);
    },

    slider: function slider(element) {

        var slider = document.getElementById(element);

        noUiSlider.create(slider, {
            start: [$(slider).data('lower'), $(slider).data('upper')],
            connect: true,
            step: 1,
            range: {
                'min': [$(slider).data('min')],
                'max': [$(slider).data('max')]
            },
            format: wNumb({
                decimals: 0
            })
        });

        slider.noUiSlider.on('update', function (values, handle) {
            $("." + element + "_lower").html('&euro; ' + values[0]);
            $("." + element + "_upper").html('&euro; ' + values[1]);
        });
    },

    clearAll: function clearAll() {

        $filters.el().find('input , select').not('[name="_token"]').each(function () {
            $(this).val('');
        });
        $filters.request();
    },

    request: function request() {

        var data = $filters.el().find('form').serialize();

        var overview = $('.overview');

        overview.addClass('loading');

        clearTimeout($filters.timeout);
        $filters.timeout = setTimeout(function () {

            $.post("/filter", data).success(function (html) {

                overview.html($(html).find(".overview").html());

                var found = $(html).find("#found").text();
                $('#found').text(found);

                $filters.loader.reInit();
            }).always(function () {

                overview.removeClass('loading');
            });
        }, 300);
    }
};

$filters.loader = {

    allLoaded: false,
    isLoading: false,
    init: function init() {

        $filters.loader.checkAllLoaded(parseInt($("#found").text()));
        $(window).scroll($filters.loader.checkOffset);
    },

    reInit: function reInit(found) {
        $filters.loader.checkAllLoaded();
    },

    reset: function reset() {},

    checkOffset: function checkOffset() {

        if ($('#overviewLoader').isOnScreen()) {
            $filters.loader.request();
        }
    },

    request: function request() {

        if ($filters.loader.isLoading || $filters.loader.allLoaded) {
            return false;
        }

        $filters.loader.isLoading = true;

        var overviewLoader = $('#overviewLoader');
        overviewLoader.find('div').show();

        $.get("/load").success(function (html) {

            overviewLoader.before($(html).find(".overview").html());
            $filters.loader.checkAllLoaded(parseInt($("#found").text()));
        }).always(function () {
            console.log('always');
            $filters.loader.isLoading = false;
            overviewLoader.find('div').hide();
        });
    },

    checkAllLoaded: function checkAllLoaded(loaded) {

        if ($('#overviewLoader').parent().find('.pro').length >= loaded) {
            $filters.loader.allLoaded = true;
        } else {
            $filters.loader.allLoaded = false;
        }
    }
};

$filters.datepickers = {

    options: {

        dateFormat: 'dd-mm-yy',
        changeMonth: true,
        changeYear: true,
        yearRange: "-100:+0",
        minDate: new Date(),
        maxDate: "+1y"
    },

    init: function init() {
        $('.datepicker').datepicker($filters.datepickers.options);
        $filters.datepickers.listeners();
    },

    listeners: function listeners() {

        var el = $filters.el();
        el.find('#available_from').datepicker('option', 'onSelect', $filters.datepickers.fromselected);
        el.find('.datepicker + .clear').on('click', $filters.datepickers.clearValue);
    },

    clearValue: function clearValue() {
        $(this).prev().val('').change();
    },

    fromselected: function fromselected(date) {

        $('#available_to').datepicker('option', 'minDate', date);
    }
};
$filters.init();

},{}],3:[function(require,module,exports){
'use strict';

var $notice = require('../helpers/notice');

function Form(container, ajax) {

    var self = this;
    self.form = container;
    self.valid = true;
    self.csrf = false;
    self.url = '';
    self.ajax = ajax || false;

    var scrollOffset = 200;

    self.init = function () {

        if (this.form instanceof jQuery === false) {
            throw new Error("Container is not a jquery instance");
        }

        self.listeners();
        self.url = container.attr('action');
    };

    self.listeners = function () {

        $(self.form).on('submit', self.onSubmit);
        self.form.find('input[data-validate][type="text"] , input[data-validate][type="password"] , textarea[data-validate]').each(function () {

            $(this).on('blur', function () {
                self.checkRules(this);
            });
        });

        self.form.find('input[data-validate][type="checkbox"] , ' + '            input[data-validate][type="radio"] , ' + '            select[data-validate]').each(function () {
            $(this).on('change', function () {
                self.checkRules(this);
            });
        });

        self.form.find('.datepicker').each(function () {

            $(this).off('blur').on('change', function () {
                self.checkRules(this);
            });
        });
    };

    self.checkRules = function (input) {

        var rules = $(input).data('validate').split('|');
        var required = rules.indexOf('req');
        if (required > -1) {

            rules.splice(required, 1);
            if (input.type == 'radio' || input.type == 'checkbox') {
                if (self.isChecked(input)) {
                    return false;
                }
            } else if (self.isRequired(input)) {
                return false;
            }
        }

        rules.forEach(function (rule) {

            self.validate(input, rule, required > -1);
        });
    };

    self.isRequired = function (input) {
        var value = $(input).val().trim();
        if (value.length === 0) {
            self.setError(input);
        } else {
            self.unsetError(input);
        }
    };

    self.isChecked = function (input) {
        if (!input.checked) {
            $(input).closest('.' + input.type).addClass('error');
            self.valid = false;
        } else {
            $(input).closest('.' + input.type).removeClass('error');
        }
    };

    self.validate = function (input, rule, required) {

        var value = $(input).val().trim();
        if (!rule) {
            return false;
        }

        if (!required && value.length === 0 && rule !== 'confirmed') {

            self.unsetError(input);
            return false;
        }

        switch (rule) {

            case 'email':
                var emailReg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                var valid = emailReg.test(value);

                if (!valid) {
                    self.setError(input, 'error');
                } else {
                    self.unsetError(input, 'error');
                }
                break;

            case 'phone':
                var myString = value.replace(/[^\d\+]/g, "");
                if (myString.length < 10) {
                    self.setError(input);
                } else {
                    self.unsetError(input);
                }
                break;

            case 'zip':

                var myString = value.replace(/[^A-Za-z0-9?]/g, "");
                if (myString.replace(/[^0-9?]/g, "").length != 4 || myString.replace(/[^A-Za-z?]/g, "").length != 2) {
                    self.setError(input);
                } else {
                    self.unsetError(input);
                }

                break;

            case 'confirmed':
                var inputName = $(input).attr('name').replace('_confirmation', '');

                if ($('[name="' + inputName + '"]').val() != $(input).val()) {
                    self.setError(input);
                } else {
                    self.unsetError(input);
                }

                break;
        }
    };

    self.setError = function (input) {

        if ($(input).is("select")) {

            $(input).parent().addClass('error');
        } else {

            $(input).addClass('error');
        }

        self.valid = false;
    };

    self.unsetError = function (input) {

        if ($(input).is("select")) {

            $(input).parent().removeClass('error');
        } else {

            $(input).removeClass('error');
        }
    };

    self.isValid = function () {
        return self.valid;
    };

    self.onSubmit = function (e) {
        self.valid = true;
        self.form.find('[data-validate*="req"] ,[data-validate*="confirmed"]').change().blur();
        if (!self.isValid()) {
            self.toTop(self.form.find('.error').first().offset().top);
            self.form.trigger("custom:invalid");
            return false;
        }

        self.setCsrfToken();
        self.form.trigger('custom:beforeSend'); //send event for some object to be able to clean up
        return self.submit();
    };

    self.post = {
        clear: function clear() {
            if (typeof self.notice != 'undefined') {
                self.notice.remove();
            }
        },
        success: function success(res) {
            self.notice = $notice.success(res);
            self.form.before(self.notice);

            self.form.slideUp(function () {
                self.form.remove();
                self = null;
            });
        },
        error: function error(res) {

            self.notice = $notice.error(res.responseJSON, {
                'classes': 'm-t-40 m-b-10'
            });
            self.form.before(self.notice);
            self.toTop(self.notice.offset().top);
        }
    };

    self.toTop = function (goto) {
        $("html, body").animate({ scrollTop: goto - scrollOffset }, 1000);
    };

    self.setCsrfToken = function () {
        if (!self.csrf) {
            self.form.append('<input type="hidden" name="antispam" value="' + new Date().getTime() + '" />');
            self.csrf = true;
        }
    };

    self.init();
}

Form.prototype.submit = function () {
    var self = this;
    if (self.ajax) {
        $.post(self.url, self.form.serialize()).always(self.post.clear).success(self.post.success).error(self.post.error);
        return false;
    }
};

module.exports = Form;

},{"../helpers/notice":6}],4:[function(require,module,exports){
'use strict';

module.exports = function (container) {
    var self = this;
    var animating = false;
    var container = container;
    var current = 0;
    var speed = 7000;

    var items = container.find('ol li');
    var parent;
    var length = items.length;
    var interval;

    this.init = function () {

        try {
            $(items[0]).addClass('active');
        } catch (e) {}

        console.log(items);

        if (length <= 1) {
            container.find('.nav').hide();
            return;
        }

        parent = items.first().parent();
        self.listeners();
        self.resetInterval();
    };

    this.listeners = function () {
        var nav = container.find('.nav');
        nav.find('.left').click(this.prev);
        nav.find('.right').click(this.next);
    };

    this.resetInterval = function () {
        clearInterval(interval);
        interval = setInterval(function () {
            console.log('wel init');
            self.next();
        }, speed);
    };

    this.next = function () {
        var fadeOut = current;

        if (animating) return;

        current += 1;
        if (current >= length) {
            current = 0;
        }

        self.resetInterval();
        self.animate(fadeOut, current);
    };

    this.prev = function () {
        var fadeOut = current;

        if (animating) return;

        current -= 1;
        if (current < 0) {
            current = length - 1;
        }

        self.resetInterval();
        self.animate(fadeOut, current);
    };

    this.animate = function (fadeOut, fadeIn) {

        animating = true;

        $(items[fadeIn]).addClass('next');
        $(items[fadeOut]).animate({ 'opacity': '0' }, 1000, function () {

            $(items).removeClass('active');
            $(this).detach().prependTo(parent);

            $(items[fadeOut]).attr('style', '');
            $(items[fadeIn]).removeClass('next').addClass('active').attr('style', '');

            animating = false;
        });
    };

    this.init();
};

},{}],5:[function(require,module,exports){
"use strict";

var $navigation = {
    el: function el() {
        return $('.fixed-top');
    },
    open: false,
    duration: 400,

    init: function init() {

        $navigation.listeners();
    },

    listeners: function listeners() {
        $(".navicon").click($navigation.toggle);
        $(window).scroll($navigation.scrolled);
        $("#mobile-menu").swipe({
            swipeRight: function swipeRight(event, direction, distance, duration, fingerCount) {
                $navigation.hide();
            }, threshold: 40
        });
    },

    scrolled: function scrolled() {
        if ($(window).scrollTop() > 50) {
            return $navigation.el().addClass('scrolled');
        }
        return $navigation.el().removeClass('scrolled');
    },

    toggle: function toggle() {

        if ($navigation.open) {
            $navigation.hide();
        } else {
            $navigation.show();
        }
    },
    show: function show() {

        $navigation.open = true;
        $(".navicon").addClass('is-active');
        $("#mobile-menu").addClass('open');
    },
    hide: function hide() {

        $navigation.open = false;
        $(".navicon").removeClass('is-active');
        $("#mobile-menu").removeClass('open');
    }

};
$navigation.init();

},{}],6:[function(require,module,exports){
'use strict';

module.exports = {
    make: function make(text, type, options) {

        options = options || false;

        var el = $('<div></div>').addClass('collapse alert alert-' + type).html('<p>' + text + '</p>').slideDown();

        if (options) {
            el = this.checkOptions(el, options);
        }

        return el;
    },
    error: function error(text, options) {
        return this.make(text, 'danger', options);
    },
    success: function success(text, options) {
        return this.make(text, 'success', options);
    },

    checkOptions: function checkOptions(el, options) {

        var classes = options.classes || false;
        if (classes) {
            el.addClass(classes);
        }

        var remove = options.remove || false;
        if (remove) {
            setTimeout(function () {
                el.slideUp(function () {
                    el.remove();
                });
            }, 3000);
        }

        return el;
    }
};

},{}]},{},[1]);
